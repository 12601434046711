import React, { Component } from "react"
import {Link, graphql} from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from "react-helmet";
import { Tabs, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Layout from "../../components/layout";
import ReactGA from 'react-ga'
import "../../css/style.css";
import "../../css/animate.min.css";
import "../../css/responsive.css";
import "../../css/bootstrap.min.css";
import check from '../../images/check-icon.svg'
import InstituteTabs from "../../components/institutiontabs";
import Swiper, { Navigation, Pagination, Scrollbar, Autoplay, Thumbs, Mousewheel, Parallax } from 'swiper';
import ScrollAnimation from 'react-animate-on-scroll';
import {Animated} from "react-animated-css";
import cardImg from '../../images/programs/degree.svg'
import cardImg1 from '../../images/programs/start online.svg'
import cardImg2 from '../../images/programs/get your visa.svg'
import cardImg3 from '../../images/programs/completed overseas.svg'

Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, Thumbs, Mousewheel, Parallax]);

class Countries  extends Component {
    constructor(props){
        super(props);
        this.state ={
            time: 90000000
        }
        
        this.readmoreFucnction = this.readmoreFucnction.bind(this)
        this.readmoreFucnction2 = this.readmoreFucnction2.bind(this)
        this.readmoreFucnction3 = this.readmoreFucnction3.bind(this)     
    }
    
    componentDidMount(){
        var swiper = new Swiper('.universities-swiper',  {
            slidesPerView: 3,
        slidesPerColumn: 2,
        spaceBetween: 10,
        observer: true,
        observeParents: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                slidesPerColumn: 1,
                spaceBetween: 0,
                observer: true,
                observeParents: true,
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 2,
                slidesPerColumn: 1,
                spaceBetween: 20,
                observer: true,
                observeParents: true,
            },
            // when window width is >= 640px
            768: {
                slidesPerView: 3,
                slidesPerColumn: 2,
                spaceBetween: 10,
                observer: true,
                observeParents: true,
            }
        }
    });
    var swiper = new Swiper('.universities-swiper',  {
        slidesPerView: 3,
    slidesPerColumn: 2,
    spaceBetween: 10,
    observer: true,
    observeParents: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 1,
            slidesPerColumn: 1,
            spaceBetween: 0,
            observer: true,
            observeParents: true,
        },
        // when window width is >= 480px
        480: {
            slidesPerView: 2,
            slidesPerColumn: 1,
            spaceBetween: 20,
            observer: true,
            observeParents: true,
        },
        // when window width is >= 640px
        768: {
            slidesPerView: 3,
            slidesPerColumn: 2,
            spaceBetween: 10,
            observer: true,
            observeParents: true,
        }
    }
});
var swiper = new Swiper('.universities-swiper',  {
    slidesPerView: 3,
slidesPerColumn: 2,
spaceBetween: 10,
observer: true,
observeParents: true,
pagination: {
    el: '.swiper-pagination',
    clickable: true,
},
breakpoints: {
    // when window width is >= 320px
    320: {
        slidesPerView: 1,
        slidesPerColumn: 1,
        spaceBetween: 0,
        observer: true,
        observeParents: true,
    },
    // when window width is >= 480px
    480: {
        slidesPerView: 2,
        slidesPerColumn: 1,
        spaceBetween: 20,
        observer: true,
        observeParents: true,
    },
    // when window width is >= 640px
    768: {
        slidesPerView: 3,
        slidesPerColumn: 2,
        spaceBetween: 10,
        observer: true,
        observeParents: true,
    }
}
});
 

var swiper = new Swiper('.blogs-swiper', {
    slidesPerView:2,
    spaceBetween: 5,
    autoplay: true,
    // direction: 'vertical',
    speed: 300,
    loop: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView:1,
            spaceBetween: 20,
        },
        480: {
            slidesPerView: 1,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 1,
            spaceBetween: 20,
        },
        // when window width is >= 480px
        992: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        // when window width is >= 640px
        1200: {
            slidesPerView: 3,
            spaceBetween: 30,
        }
    }
});


}
readmoreFucnction=()=>{
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("myBtn");

    if (dots.style.display === "none") {
        dots.style.display = "inline";
        btnText.innerHTML = "Read more";
        moreText.style.display = "none";
    } else {
        dots.style.display = "none";
        btnText.innerHTML = "Read less";
        moreText.style.display = "inline";
    }
}

 readmoreFucnction2=()=> {
    var dots = document.getElementById("dots2");
    var moreText = document.getElementById("more2");
    var btnText = document.getElementById("myBtn2");

    if (dots.style.display === "none") {
        dots.style.display = "inline";
        btnText.innerHTML = "Read more";
        moreText.style.display = "none";
    } else {
        dots.style.display = "none";
        btnText.innerHTML = "Read less";
        moreText.style.display = "inline";
    }
}

 readmoreFucnction3() {
    var dots = document.getElementById("dots3");
    var moreText = document.getElementById("more3");
    var btnText = document.getElementById("myBtn3");

    if (dots.style.display === "none") {
        dots.style.display = "inline";
        btnText.innerHTML = "Read more";
        moreText.style.display = "none";
    } else {
        dots.style.display = "none";
        btnText.innerHTML = "Read less";
        moreText.style.display = "inline";
    }
    }
    handlePopupWindow = () => {
        this.setState({ time: Math.random() })
    }
  render(){

    const uniArr = this.props.data.allWordpressWpUniversities.nodes
	const post = this.props.data.allWordpressPage.nodes[0];
	const metatitle = post.acf.meta_title ;
	const metadescription = post.acf.meta_description; 
const focus_keyphrase = post.acf.focus_keyphrase; 
const canonical_url = `https://theworldgrad.com${this.props.path}`; 

    const cardData = [
        {
            image: cardImg, title: "Choose Your University", text: "Get admission to your preferred university through The WorldGrad and receive your offer letter."
        },
        {
            image: cardImg1, title: "Start Online", text: "Complete the first half of Year 1 online in just 3.5 months (2 modules in 7 weeks)  without any delays thanks to multiple intakes (intake every 2 months)."
        },
        {
            image: cardImg2, title: "Get Your Visa", text: "Complete your visa process while studying and be ready to go overseas by the end of the program."
        },
        {
            image: cardImg3, title: "Complete Overseas", text: "Progress to the overseas campus to complete the remainder of your degree and apply for post study work rights."
        }
    ]
    
    return (
		<div clsss="countrie-page">
			<Layout>
				<Helmet>
				<title>{metatitle}</title>
          <meta name="description" content={metadescription} />
          {(() => {if(post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) {return (<meta name="keywords" content={focus_keyphrase} /> )} })()}
          <link rel="canonical" href={canonical_url} /> 
           {(() => {if(post.acf.dynamic_schema != '' && post.acf.dynamic_schema != null) {return (<script type="application/ld+json">{JSON.stringify (JSON.parse(post.acf.dynamic_schema))}</script> )} })()}  
          
	</Helmet>
 {/*canonical_url*/}
	<div class="newStyle">  
		 <div className="container">
		 <ol class="transparentbreadcrumb breadcrumb breadcrumb-new">
											<li class="breadcrumb-item">
												<a href="/">Home </a>
											</li>
											<li class="breadcrumb-item">
												<a href="/countries">Countries </a>
											</li>
											<li class="breadcrumb-item active"> {post.title}</li>
										</ol>
				</div>
				</div>
				<section class="countrie-banner-sec" id="country-page-banner-section-aus">
					<Animated animationIn="fadeInUp" animationOut="fadeInDown" isVisible={true}>
						<div class="container">
							<div class="row">
								<div class="col-12">
									<div class="countrie-banner-inner">
										
										<div class="text-box wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s" id="country-banner-image-container">
										{(() => {
                                                if(post.acf.custom_h1 != '' && post.acf.custom_h1 != null) {
                                                        return (<h1>{post.acf.custom_h1}</h1> )
                                                    } else {
                                                        return (<h1 class="default">Learn More About Studying In Australia</h1> ) 
                                                    }
                                                    })()}

										</div>
									</div>
								</div>
							</div>
						</div>
					</Animated>
				</section>

				<section style={{ backgroundColor: "#fff" }} className="py-md-1">
					<div className="container country-page-features-main-container">
						<div className="row country-page-feature-container justify-content-center align-items-center">
							<div className="col-md-6">
								<ScrollAnimation animateIn="fadeInLeft" style={{ "animation-duration": "1s" }}>
									<Img
										fluid={this.props.data.highway.childImageSharp.fluid}
										imgStyle={{
											borderRadius: "15px",
										}}
										style={{
											maxWidth: "500px",
											maxHeight: "600px",
										}}
									/>
								</ScrollAnimation>
							</div>
							<div className="col-md-6 mt-3 mt-md-0">
								<ScrollAnimation animateIn="fadeInRight" style={{ "animation-duration": "1s" }}>
									<h3>Australia: Overview</h3>
									<p className="country-usa-overview">Australia has become one of the most exciting destinations for students and professionals. Thanks to its globally ranked universities and developed economy, Australia is attractive to students from across the globe. A highly welcoming environment for students - international and domestic, coupled with the great education and lifestyle opportunities that the country offers Australia is a no-brainer when it comes to choosing an overseas destination.</p>
									<ul>
										<li className="country-usa-overview mb-3">
											<span>
												<img id="country-page-tick-para" className="mr-2 country-page-tick-img" src={check} alt="✓" />
											</span>
											Currently home to over 700,000 international students
										</li>
										<li className="country-usa-overview mb-3">
											<span>
												<img id="country-page-tick-para" className="mr-2 country-page-tick-img" src={check} alt="✓" />
											</span>
											Ranked 2nd best country to live in as per the United Nations study on quality of life
										</li>
										<li className="country-usa-overview mb-3">
											<span>
												<img id="country-page-tick-para" className="mr-2 country-page-tick-img" src={check} alt="✓" />
											</span>
											6 of the world’s top 100 universities are in Australia
										</li>
										<li className="country-usa-overview mb-3">
											<span>
												<img id="country-page-tick-para" className="mr-2 country-page-tick-img" src={check} alt="✓" />
											</span>
											International student friendly visa system that also allows for work while studying
										</li>
									</ul>
									<p className="country-usa-overview">
										Here are few more reasons to{" "}
										<a href="https://theworldgrad.com/blog-detail/why-should-you-consider-studying-in-australia/" target="_blank">
											study in Australia
										</a>
									</p>
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</section>

				<section>
					<div className="container country-page-features-main-container">
						<div className="row country-page-feature-container justify-content-center align-items-center">
							<div className="col-md-6 order-first order-md-last">
								<ScrollAnimation animateIn="fadeInLeft" style={{ "animation-duration": "1s" }}>
									<Img
										fluid={this.props.data.sydneyHouseOpera.childImageSharp.fluid}
										imgStyle={{
											borderRadius: "15px",
										}}
										style={{
											maxWidth: "500px",
											maxHeight: "600px",
										}}
									/>
								</ScrollAnimation>
							</div>
							<div className="col-md-6 mt-3 mt-md-0">
								<ScrollAnimation animateIn="fadeInRight" style={{ "animation-duration": "1s" }}>
									<h3>Cost Of Education In Australia</h3>

									<ul>
										<li className="country-usa-overview mb-2">
											<span>
												<img className="mr-2 country-page-tick-img" src={check} alt="✓" />
											</span>
											<h4 className="mb-5" style={{ display: "inline" }}>
												University Tuition Fees
											</h4>
											<p className="country-usa-overview-mini mt-3">Depending on the course you choose and the institution you enrol in, the annual fees will be in the range of AUD 32,448 - 39,600</p>
										</li>
										<li className="country-usa-overview mb-2">
											<span>
												<img className="mr-2 country-page-tick-img" src={check} alt="✓" />
											</span>
											<h4 className="mb-5" style={{ display: "inline" }}>
												Living Costs
											</h4>
											<p className="country-usa-overview-mini mt-3">Depending on your city of residence and spending habits, living expenses are likely to cost anywhere between AUD 9,17 - 2,000 per month</p>
										</li>
										<li className="country-usa-overview mb-2">
											<span>
												<img className="mr-2 country-page-tick-img" src={check} alt="✓" />
											</span>
											<h4 className="mb-5" style={{ display: "inline" }}>
												Scholarships and Savings with The WorldGrad
											</h4>
											<p className="country-usa-overview-mini mt-3">By completing a part of Year 1 online from home with The WorldGrad you can save up to AUD 63,600: ~AUD 39,600 in tuition fees and ~AUD 24,000 in living costs</p>
											<p className="country-usa-overview-mini">Additional scholarships of up to 25% on overseas tuition fees available to The WorldGrad students at select partner institutions</p>
										</li>
										<p className="country-usa-overview-mini">
											Get to know more about{" "}
											<a href="https://theworldgrad.com/blog-detail/cost-of-education-in-australia/" target="_blank">
												cost of education in Australia
											</a>
										</p>
									</ul>
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</section>

				<section style={{ backgroundColor: "#fff" }} className="py-md-1">
					<div className="container country-page-features-main-container">
						<div className="row country-page-feature-container justify-content-center align-items-center">
							<div className="col-md-6">
								<ScrollAnimation animateIn="fadeInLeft" style={{ "animation-duration": "1s" }}>
									<Img
										fluid={this.props.data.ausPassport.childImageSharp.fluid}
										imgStyle={{
											borderRadius: "15px",
										}}
									/>
								</ScrollAnimation>
							</div>
							<div className="col-md-6 mt-3 mt-md-0">
								<ScrollAnimation animateIn="fadeInRight" style={{ "animation-duration": "1s" }}>
									<h3>Australia Student Visa Guidance</h3>

									<p className="country-usa-overview-mini">You need a Confirmation of Enrolment Certificate from the Australian institution and meet GTE requirements to qualify for a student visa</p>

									<ul>
										<li className="country-usa-overview mb-2">
											<span>
												<img className="mr-2 country-page-tick-img" src={check} alt="✓" />
											</span>
											<h4 className="mb-5" style={{ display: "inline" }}>
												Visa Requirements
											</h4>
											<p className="country-usa-overview-mini mt-3">GTE assessment is done by the institution based on your academic record and English proficiency (IELTS/PTE/etc.). You would also need to show proof of sufficient funds to cover your airfare, course fees and living costs. Additionally, you have to meet health requirements, criminal record check and provide evidence of Overseas Student Health Cover</p>
										</li>
										<li className="country-usa-overview mb-2">
											<span>
												<img className="mr-2 country-page-tick-img" src={check} alt="✓" />
											</span>
											<h4 className="mb-5" style={{ display: "inline" }}>
												Timelines
											</h4>
											<p className="country-usa-overview-mini mt-3">Your student visa will take a maximum of 4 weeks to get processed. The validity of your student visa will vary depending on the month in which your course ends, i.e. it's valid till 15th March of the subsequent year if the course ends in Nov-Dec or until 2 months after course completion if the course ends between Jan-Oct</p>
										</li>
										<li className="country-usa-overview mb-2">
											<span>
												<img className="mr-2 country-page-tick-img" src={check} alt="✓" />
											</span>
											<h4 className="mb-5" style={{ display: "inline" }}>
												Post-Study Work Rights
											</h4>
											<p className="country-usa-overview-mini mt-3">You can apply for your Post-Study Work Rights within 6 months of completing your studies. As a undergraduate you are eligible for Post-Study Work Rights for up to 5 years.</p>
											<p className="country-usa-overview-mini">All programs offered by The WorldGrad qualify you for post-study visa in Australia</p>
										</li>
									</ul>

									<p className="country-usa-overview-mini">
										Get to know more about{" "}
										<a href="https://theworldgrad.com/blog-detail/australia-student-visa-all-that-you-need-to-know/" target="_blank">
											Australian student visa
										</a>
									</p>
								</ScrollAnimation>
								<a
									class="btn-default visa-visit-btn mx-auto"
									href="https://www.studyinaustralia.gov.au/English/how-to-apply"
									target="_blank"
									onClick={() => {
										this.handlePopupWindow();
										ReactGA.event({
											category: "Countries",
											action: "Clicks",
											label: "Visit Visa Website - Our Visa Guidance for Australia",
										});
									}}>
									VIsit VISA website
								</a>
							</div>
						</div>
					</div>
				</section>

				<section>
					<div className="container country-page-features-main-container">
						<div className="row country-page-feature-container justify-content-center align-items-center">
							<div className="col-md-6 order-first order-md-last">
								<ScrollAnimation animateIn="fadeInLeft" style={{ "animation-duration": "1s" }}>
									<Img
										fluid={this.props.data.money.childImageSharp.fluid}
										imgStyle={{
											borderRadius: "15px",
										}}
										style={{
											maxWidth: "500px",
											maxHeight: "600px",
										}}
									/>
								</ScrollAnimation>
							</div>
							<div className="col-md-6 mt-3 mt-md-0">
								<ScrollAnimation animateIn="fadeInRight" style={{ "animation-duration": "1s" }}>
									<h3>Employability & Careers In Australia</h3>

									<ul>
										<li className="country-usa-overview mb-2">
											<span>
												<img className="mr-2 country-page-tick-img" src={check} alt="✓" />
											</span>
											<h4 className="mb-5" style={{ display: "inline" }}>
												Industries
											</h4>
											<p className="country-usa-overview-mini mt-3">
												With GDP of AUD 1.98 trillion, Australia is the 12th largest economy by GDP as of 2021. In the highly developed mixed economy, health & education and minerals are the major Australian industries having 13.2% and 11.5% shares respectively. Manufacturing, finance and construction are the other industries driving the growth of the Australian economy.
												<br />
												<a href="https://www.rba.gov.au/education/resources/snapshots/economy-composition-snapshot/" target="_blank">
													Source
												</a>
											</p>
										</li>
										<li className="country-usa-overview mb-2">
											<span>
												<img className="mr-2 country-page-tick-img" src={check} alt="✓" />
											</span>
											<h4 className="mb-5" style={{ display: "inline" }}>
												Careers
											</h4>
											<p className="country-usa-overview-mini mt-3">
												Some of the top careers in Australia are:
												<br />
												1. IT Systems Architect
												<br />
												2. Engineering & IT Manager
												<br />
												3. Cloud Engineer
												<br />
												4. Doctor/Physician
												<br />
												5. Wellbeing Engineer
												<br />
											</p>
											<p className="country-usa-overview-mini">
												Get to know more about{" "}
												<a href="https://theworldgrad.com/blog-detail/career-opportunities-for-indian-students-in-australia/" target="_blank">
													careers in Australia
												</a>
											</p>
										</li>
										<li className="country-usa-overview mb-2">
											<span>
												<img className="mr-2 country-page-tick-img" src={check} alt="✓" />
											</span>
											<h4 className="mb-5" style={{ display: "inline" }}>
												Salary
											</h4>
											<p className="country-usa-overview-mini mt-3">Median salary of a fresh graduate in Australia generally ranges from AUD 54,000 - AUD 1,32,000</p>
										</li>
									</ul>
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</section>

				<section style={{ backgroundColor: "#fff" }} className="py-md-1">
					<div className="container country-page-features-main-container">
						<div className="row country-page-feature-container-last justify-content-center">
							<div className="col-md-6">
								<ScrollAnimation animateIn="fadeInLeft" style={{ "animation-duration": "1s" }}>
									<h3 className="mb-2 mb-md-4 text-center pt-4 pt-md-0"> Credit Issuing Partners </h3>
									<img src="https://assets.theworldgrad.com/gatsby-assets/xtra/16.png" alt="University of Wollongong" class="img-fluid mb-3 d-flex mx-auto shadow p-1" style={{ borderRadius: "15px", width: "250px" }} />
		
								</ScrollAnimation>
							</div>
							<div className="col-md-6 mt-3 mt-md-0">
								<ScrollAnimation animateIn="fadeInRight" style={{ "animation-duration": "1s" }}>
									<h3>The WorldGrad path to a degree in Australia</h3>

									<p className="country-usa-overview">Fast track your overseas education from best Australian universities with the help of The WorldGrad programs</p>

									<ul>
										<li className="country-usa-overview mb-3">
											<span>
												<img id="country-page-tick-para" className="mr-2 country-page-tick-img" src={check} alt="✓" />
											</span>
											With The WorldGrad programs you can complete up to one year of your course from home
										</li>
										<li className="country-usa-overview mb-3">
											<span>
												<img id="country-page-tick-para" className="mr-2 country-page-tick-img" src={check} alt="✓" />
											</span>
											Upon completing the smart program, you can move to a top ranked university campus and complete your degree
										</li>
										<li className="country-usa-overview mb-3">
											<span>
												<img id="country-page-tick-para" className="mr-2 country-page-tick-img" src={check} alt="✓" />
											</span>
											The WorldGrad programs help you complete your dream career with savings of up to AUD 14,404 - 50,410
										</li>
										<li className="country-usa-overview mb-3">
											<span>
												<img id="country-page-tick-para" className="mr-2 country-page-tick-img" src={check} alt="✓" />
											</span>
											The WorldGrad offers two programs which is the Global Online Accelerator Program (GOAP) and Global Year 1 Program (GY1)
										</li>
									</ul>

									<div className="row">
							
										<div className="col-md-6">
											<a
												class="btn-default visa-visit-btn mx-auto text-center"
												id="GOAP-btn"
												href="/global-online-accelerator-program"
												onClick={() => {
													ReactGA.event({
														category: "Countries",
														action: "Clicks",
														label: "Visit Visa Website - Our Visa Guidance for Australia",
													});
												}}>
												GLOBAL ONLINE ACCELERATOR PROGRAM
											</a>
											</div>
											<div className="col-md-6">
											<a
												class="btn-default visa-visit-btn mx-auto text-center"
												id="GOAP-btn"
												href="/global-year-1-program/"
												onClick={() => {
													ReactGA.event({
														category: "Countries",
														action: "Clicks",
														label: "Visit Visa Website - Our Visa Guidance for Australia",
													});
												}}>
												GLOBAL YEAR 1 PROGRAM
											</a>
										</div>
									</div>
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</section>

				<div class="container" style={{ backgroundColor: "#f8f8f8" }}>
					<div class="row">
						<div class="col-12">
							<h2 className="text-center mb-0 mt-md-4">Featured Universities</h2>
							<Tabs>
								<TabPanel>
									<section id="marzen" class="tab-panel">
										<div class="interest-explore-inner">
											<div class="swiper-container universities-swiper">
											<div className="swiper-wrapper row justify-content-center m-0">
    {
        uniArr.map(university => {
            const bannerImage = university.acf.banner_image ? university.acf.banner_image.source_url : (university.acf.university_thumbnail ? university.acf.university_thumbnail.source_url : null);

            // Check if `acf.university_detail_page` array includes "yes" (case-insensitive)
            const hasDetailPage = Array.isArray(university.acf.university_detail_page) && university.acf.university_detail_page.some(page => page.toLowerCase() === "yes");
//console.log("university_detail_page->", hasDetailPage);
            // Check if `acf.website_link` exists
            const websiteLink = university.acf.website_url2 ? university.acf.website_url2 : null;

            return (
                <div className="col-md-4" key={university.id}>
                    {
                        hasDetailPage ? (
                            <Link to={`/university-detail/${university.slug}`}>
                                <div className="swiper-slide" value="Open" onClick={() => {
                                    ReactGA.event({
                                        category: "Institutions",
                                        action: "Clicks",
                                        label: `UK - ${university.title}`
                                    })
                                }}>
                                    <InstituteTabs
                                        ImageUrl={bannerImage}
                                        UniversityName=""
                                    />
                                </div>
                            </Link>
                        ) : websiteLink ? (
                            <a href={websiteLink} target="_blank" rel="noopener noreferrer">
                                <div className="swiper-slide" value="Open" onClick={() => {
                                    ReactGA.event({
                                        category: "Institutions",
                                        action: "Clicks",
                                        label: `External Website - ${university.title}`
                                    })
                                }}>
                                    <InstituteTabs
                                        ImageUrl={bannerImage}
                                        UniversityName=""
                                    />
                                </div>
                            </a>
                        ) : (
                            <div className="swiper-slide" value="No Link">
                                <InstituteTabs
                                    ImageUrl={bannerImage}
                                    UniversityName=""
                                />
                            </div>
                        )
                    }
                </div>
            )
        })
    }
</div>
											</div>
										</div>
									</section>
								</TabPanel>
							</Tabs>
						</div>
					</div>
				</div>
			</Layout>
		</div>
	);
  }
}
export default Countries;

export const WorldGradUniversities = graphql`
    {
		allWordpressPage(filter: {wordpress_id: {in: 4971}}) {
            nodes {
              title
              wordpress_id
              acf {
                 
                meta_title
meta_description
focus_keyphrase
canonical_url
dynamic_schema
custom_h1        
          }
        }
    }  
        allWordpressWpUniversities(filter: {acf: {country: {eq: "Australia"}}}) {
            nodes {
                id
                slug
                title
                acf {
                 university_detail_page
 website_url
                    university_thumbnail {
                        source_url
                    }
					banner_image {
						source_url
					}
                }
            }
        }
        highway: file(relativePath: {eq: "countries-aus-highway.jpg"}) {
            base
            childImageSharp {
                fluid (maxHeight: 600, maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        sydneyHouseOpera: file(relativePath: {eq: "countries-aus-SHO.jpg"}) {
            base
            childImageSharp {
                fluid (maxHeight: 600, maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        ausPassport: file(relativePath: {eq: "countries-aus-passport.jpg"}) {
            base
            childImageSharp {
                fluid (maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        money: file(relativePath: {eq: "countries-money.jpg"}) {
            base
            childImageSharp {
                fluid (maxWidth: 500, maxHeight: 600) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
